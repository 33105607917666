var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._b({
    on: {
      "change": _vm.emit
    },
    scopedSlots: _vm._u([!_vm.isColor && _vm.hasError ? {
      key: "append",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "size": "20",
                  "color": "error"
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-alert-circle-outline ")])];
            }
          }], null, false, 1450465116)
        }, [_c('ul', [_vm.hasNoName ? [_c('li', [_vm._v("옵션명은 필수 입력사항입니다.")])] : _vm._e(), _vm.hasSameName ? [_c('li', [_vm._v("동일한 이름의 옵션이 있습니다.")])] : _vm._e()], 2)])];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__verticalTable, {
    disabled: _vm.disabled
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }