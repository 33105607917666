<template>
    <v-combobox v-model="form.values" multiple item-text="name" append-icon="" placeholder="항목 작성 후 Enter키를 입력해주세요" v-bind="attrs_input__verticalTable" v-on="{ input }">
        <template #selection="{ item, parent }">
            <v-chip outlined label close @click:close="parent.selectItem(item)">
                {{ item.name }}
                <v-menu nudge-bottom="10px" nudge-left="100px" max-width="400px" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                    </template>
                    <v-card class="pa-3">
                        <v-text-field v-model="item.changedName" v-bind="{ ...attrs_input__console }" label="수정(엔터 시 저장)" @keydown.enter="save(item)" />
                        <!-- <v-btn @click="menu = false" /> -->
                    </v-card>
                </v-menu>
            </v-chip>
        </template>
    </v-combobox>
</template>

<script>
import { attrs_input__verticalTable, attrs_input__console } from "@/assets/variables/attrs";
import { initProductOptionCriterion, initProductOptionCriterionValue } from "@/assets/variables/inits";

export default {
    props: {
        value: { type: Object, default: initProductOptionCriterion },
    },
    setup: () => ({
        attrs_input__verticalTable,
        attrs_input__console,
    }),
    data: () => ({
        form: initProductOptionCriterion(),
    }),
    methods: {
        sync() {
            this.form = initProductOptionCriterion(this.value);
            this.form.values = this.form.values.map((value) => ({ ...value, changedName: value.name }));
        },
        emit() {
            this.$emit("input", this.form);
        },
        input(values = []) {
            this.form.values = values.map((item) => initProductOptionCriterionValue(typeof item == "object" ? item : { name: item }));
            this.emit();
        },
        save(item) {
            item.name = item.changedName;
            item.changedName = "";
            this.emit();
            this.$emit("updateCriteria", item);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
