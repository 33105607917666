<template>
    <v-select v-model="form.usage.target.type" label="사용대상제한" v-bind="{ ...attrs_input__console, items, disabled }" @input="emit" />
</template>

<script>
import { mapGetters } from "vuex";

import { attrs_input__console } from "@/assets/variables/attrs";
import { initShopCoupon } from "@/assets/variables/inits";
import { USAGE_TARGET_PRICES, USAGE_TARGET_TYPES } from "@/assets/variables/constants";

export default {
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    setup: () => ({
        attrs_input__console,
    }),
    data: () => ({
        form: initShopCoupon(),
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
        hasSeller() {
            return !!this.form._seller;
        },
        items() {
            // if (this.hasConsoleScope && !this.hasSeller) return [USAGE_TARGET_TYPES.ALLITEMS, USAGE_TARGET_TYPES.PRODUCTS, USAGE_TARGET_TYPES.CATEGORY];
            // else return [USAGE_TARGET_TYPES.ALLITEMS, USAGE_TARGET_TYPES.PRODUCTS];
            return [USAGE_TARGET_TYPES.ALLITEMS, USAGE_TARGET_TYPES.PRODUCTS, USAGE_TARGET_TYPES.CATEGORY];
        },
        disabled() {
            return this.form?.usage?.target?.price == USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value;
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
