var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', _vm._g(_vm._b({
    attrs: {
      "multiple": "",
      "item-text": "name",
      "append-icon": "",
      "placeholder": "항목 작성 후 Enter키를 입력해주세요"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', {
          attrs: {
            "outlined": "",
            "label": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " "), _c('v-menu', {
          attrs: {
            "nudge-bottom": "10px",
            "nudge-left": "100px",
            "max-width": "400px",
            "offset-y": "",
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-pencil")])];
            }
          }], null, true)
        }, [_c('v-card', {
          staticClass: "pa-3"
        }, [_c('v-text-field', _vm._b({
          attrs: {
            "label": "수정(엔터 시 저장)"
          },
          on: {
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return _vm.save(item);
            }
          },
          model: {
            value: item.changedName,
            callback: function ($$v) {
              _vm.$set(item, "changedName", $$v);
            },
            expression: "item.changedName"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1)], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.form.values,
      callback: function ($$v) {
        _vm.$set(_vm.form, "values", $$v);
      },
      expression: "form.values"
    }
  }, 'v-combobox', _vm.attrs_input__verticalTable, false), {
    input: _vm.input
  }));

}
var staticRenderFns = []

export { render, staticRenderFns }