var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "기본정보"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_vm.hasConsoleScope ? [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('basic-seller', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)] : _vm._e(), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "쿠폰명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "text-align-right-field",
    attrs: {
      "label": "발급수량제한",
      "suffix": "개"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.count,
      callback: function ($$v) {
        _vm.$set(_vm.form, "count", $$v);
      },
      expression: "form.count"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input__console), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('basic-value', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('basic-limit', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }