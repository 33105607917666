<template>
    <view-section-card title="상품옵션" hideDivider v-bind="{ loading }">
        <template #actions> <v-switch v-model="form.optionsConf.enabled" v-bind="attrs_switch__console" @change="emit"></v-switch> </template>
        <v-expand-transition>
            <v-sheet v-show="form.optionsConf.enabled">
                <v-divider />
                <v-card-text class="pa-0">
                    <vertical-table-row term="정렬" divider :props="rowProps">
                        <v-sheet class="mx-n4">
                            <v-select v-model="form.optionsConf.sort" :items="sortItems" v-bind="attrs_input__verticalTable" @change="emit" />
                        </v-sheet>
                    </vertical-table-row>
                    <vertical-table-row term="분류" divider :props="rowProps">
                        <v-sheet class="mx-n4"> <options-criteria v-model="form.optionsConf.criteria" v-on="{ generate }" @input="emit" @updateCriteria="updateCriteria" @updateCriteriaName="updateCriteriaName" /> </v-sheet>
                    </vertical-table-row>
                    <vertical-table-row term="목록" divider hideDivider :props="rowProps">
                        <v-sheet class="mx-n4">
                            <v-data-table v-model="selected" :items="form.options" v-bind="{ headers }" item-key="name" show-select class="product-options-table">
                                <template #[`header.value`]>
                                    <v-row class="ma-0">
                                        <v-col cols="12">옵션명</v-col>
                                    </v-row>
                                    <v-expand-transition>
                                        <v-sheet v-show="form.options[0]" color="transparent">
                                            <v-divider />
                                            <v-row class="ma-0">
                                                <template v-for="(name, index) in criteriaNames">
                                                    <v-col :key="index"> {{ decodeURIComponent(name?.trim?.() || name || "") }} </v-col>
                                                    <v-divider v-if="index != criteriaNames.length - 1" vertical :key="`${index}-divider`" />
                                                </template>
                                            </v-row>
                                        </v-sheet>
                                    </v-expand-transition>
                                </template>
                                <template #top>
                                    <options-edit-bulk v-model="form.options" v-bind="{ selected }" @input="emit" />
                                    <v-divider />
                                </template>
                                <template #[`item.value`]="{ item }">
                                    <v-row class="ma-0">
                                        <template v-for="(value, index) in item.value.split('/')">
                                            <v-col :key="index" style="height: 48px">{{ decodeURIComponent(value) }}</v-col>
                                            <v-divider v-if="index != criteriaNames.length - 1" vertical :key="`${index}-divider`" />
                                        </template>
                                    </v-row>
                                </template>
                                <template #[`item.code`]="{ item }">
                                    <v-text-field v-model="item.code" class="caption" v-bind="attrs_input__verticalTable" @input="emit" />
                                </template>
                                <template #[`item.price`]="{ item }">
                                    <v-currency-field v-model="item.price" suffix="원" class="caption" v-bind="attrs_input__verticalTable" @input="emit" />
                                </template>
                                <template #[`item.stock`]="{ item }">
                                    <v-currency-field v-model="item.stock" class="caption" v-bind="attrs_input__verticalTable" @input="emit" />
                                </template>
                                <template #[`item.enabled`]="{ item }">
                                    <v-simple-checkbox v-model="item.enabled" dense color="primary" class="d-inline-block mr-n2" @input="emit" />
                                </template>
                                <template #[`item.actions`]="{ item }">
                                    <v-btn text tile color="red" height="48" @click="remove(item)">
                                        <v-icon>mdi-minus</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-sheet>
                    </vertical-table-row>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_input__verticalTable, attrs_switch__console, initDataTableHeaders, initProduct, initProductOption, PRODUCT_OPTION_CRITERIA_TYPES, PRODUCT_OPTION_SORTS } from "@/assets/variables";

import OptionsCriteria from "./options-criteria.vue";
import OptionsEditBulk from "./options-edit-bulk.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import VerticalTableRow from "@/components/console/dumb/vertical-table-row.vue";

const rowProps = {
    rowProps: { class: "ma-0", align: "center" },
    termProps: { class: "pa-4", cols: 2, md: 1 },
    dataProps: { class: "pa-4 my-n4" },
};

const sortItems = Object.values(PRODUCT_OPTION_SORTS);

export default {
    components: {
        OptionsCriteria,
        OptionsEditBulk,
        ViewSectionCard,
        VerticalTableRow,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),

        selected: [],

        rowProps,
        sortItems,
        attrs_switch__console,
        attrs_input__verticalTable,
    }),
    computed: {
        headers() {
            return initDataTableHeaders([
                { text: "옵션명", value: "value", sortable: false, cellClass: "pa-0", class: "pa-0" },
                { width: 120, text: "상품코드", value: "code", cellClass: "pa-0" },
                { width: 120, text: "추가금액", value: "price", cellClass: "pa-0" },
                { width: +80, text: "재고", value: "stock", cellClass: "pa-0" },
                { width: +64, text: "사용", value: "enabled", sortable: false, cellClass: "pa-0", align: "center" },
                { width: +64, text: "", value: "actions", sortable: false, cellClass: "pa-0" },
            ]);
        },
        criteriaNames() {
            return this.form.optionsConf.criteria.map(({ name }) => name);
        },
    },
    methods: {
        async sync() {
            this.form = initProduct(this.value);
            this.setOptionValues();
            this.selected = [];
        },

        async emit() {
            if (this.form.optionsConf.enabled) {
                if (this.form.choiceesConf.enabled) {
                    this.form.choiceesConf.enabled = false;
                }
            }
            this.$emit("input", this.form);
        },

        setOptionValues() {
            this.form.options = this.form.options.map((option) => {
                if (option._values) return option;
                let { name } = option;
                let names = name.split("/").map((name) => decodeURIComponent(name.trim()));
                let _values = names.map((pair) => {
                    let [key, value] = pair.split(":").map((el) => el.trim());
                    let conf = this.form.optionsConf.criteria.find(({ name }) => name === key);
                    // return conf.values.find(({ name }) => name === value)?._id;
                    return conf.values.find(({ name }) => name === value)?.tempId;
                });

                return { ...option, _values };
            });
        },

        updateCriteria(item) {
            if(this.form.options.some(({ _values }) => _values.includes(undefined))){
                alert("해당 목록 중 적용되지않은 옵션이 있습니다. 목록으로 적용 버튼을 클릭해주세요.");
                return;
            }
            this.form.options = this.form.options.map((option) => {
                // if (item?._id && (option?._values || []).includes(item?._id)) {
                if (item?.tempId && (option?._values || []).includes(item?.tempId)) {
                    const names = option?.name.split("/").map((el) => decodeURIComponent(el.trim()));
                    let changedCriterias = names.map((pair) => {
                        let [key, value] = pair.split(":").map((el) => el.trim());
                        let conf = this.form.optionsConf.criteria.find(({ name }) => name === key);
                        // if (conf.values.some(({ _id }) => _id === item._id)) {
                        if (conf.values.some(({ tempId }) => tempId === item.tempId)) {
                            let { name, hexa } = conf.values.find(({ tempId }) => tempId === item.tempId);
                            return { conf: conf.name, value: name, hexa };
                        } else {
                            let { name, hexa } = conf.values.find(({ name }) => name === value);
                            return { conf: conf.name, value: name, hexa };
                        }
                    });
                    const name = changedCriterias.reduce((acc, cur, index) => {
                        let str = `${encodeURIComponent(cur?.conf)}: ${encodeURIComponent(cur?.value)}`;
                        acc += index === 0 ? `${str}` : ` / ${str}`;
                        return acc;
                    }, "");

                    const value = changedCriterias.reduce((acc, cur, index) => {
                        let str = `${encodeURIComponent(cur?.value)}`;
                        acc += index === 0 ? `${str}` : ` / ${str}`;
                        return acc;
                    }, "");

                    const colorValue = changedCriterias.find(({ conf }) => conf === "색상");

                    return { ...option, name, value, color: { name: colorValue?.colorName, hexa: colorValue?.hexa } };
                }
                return option;
            });
            this.emit();
        },

        updateCriteriaName() {
            this.form.options = this.form.options.map((option) => {
                const values = option?.value.split("/").map((el) => decodeURIComponent(el.trim()));
                const name = this.criteriaNames.reduce((acc, cur, index) => {
                    let str = `${encodeURIComponent(cur)}: ${encodeURIComponent(values[index])}`;
                    acc += index === 0 ? `${str}` : ` / ${str}`;

                    return acc;
                }, "");

                return { ...option, name };
            });

            this.emit();
        },

        generate() {
            let options = this.form.optionsConf.criteria.reduce((options, criterion) => {
                if (!options.length) {
                    options = criterion.values.map(({ name, hexa, tempId }) =>
                        initProductOption({
                            hexa,
                            name: `${encodeURIComponent(criterion.name)}: ${encodeURIComponent(name)}`,
                            value: encodeURIComponent(name),
                            enabled: true,
                            tempId__color: criterion.type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value ? tempId : undefined,
                        })
                    );
                } else {
                    options = options.flatMap((option) =>
                        criterion.values.map(({ name, hexa, tempId }) =>
                            initProductOption({
                                hexa,
                                name: `${option.name} / ${encodeURIComponent(criterion.name)}: ${encodeURIComponent(name)}`,
                                value: `${option.value} / ${encodeURIComponent(name)}`,
                                enabled: true,
                                tempId__color: option.tempId__color || (criterion.type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value ? tempId : undefined),
                            })
                        )
                    );
                }
                return options;
            }, []);

            this.form.options = options.map((option) => ({
                ...option,
                ...((this.form.options || []).find(({ name }) => name == option.name) || {}),
                tempId__color: option.tempId__color,
            }));
            this.emit();
        },

        remove(item) {
            const index = this.form.options.findIndex(({ tempId }) => tempId == item.tempId);
            if (0 <= index) this.form.options.splice(index, 1);
            this.emit();
        },

        decodeURIComponent,
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input__slot {
        background-color: rgba(255, 255, 255, 0) !important;

        &:hover {
            background: rgba(255, 255, 255, 0.75) !important;
        }
    }
}
.product-options-table ::v-deep {
    th:first-of-type,
    td:first-of-type {
        width: 64px !important;
        text-align: center !important;
        border-right: thin solid rgba(0, 0, 0, 0.12);

        .v-simple-checkbox {
            display: inline-block;
        }

        .v-input--selection-controls__input {
            margin: 0;
        }
    }
}
</style>
