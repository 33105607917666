<template>
    <v-currency-field v-model="form.share" label="판매자 할인부담률" v-bind="props__share" @input="emit" />
</template>

<script>
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

import { attrs_input__console } from "@/assets/variables/attrs";
import { initShopCoupon } from "@/assets/variables/inits";

export default {
    components: {
        AutocompleteUser,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),
    }),
    computed: {
        props__share() {
            return {
                ...attrs_input__console,

                min: 0,
                max: 100,
                suffix: "%",
                // disabled: !this.form._seller,

                class: "text-align-right-field",
            };
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>