<template>
    <v-chip-group column class="px-3">
        <draggable :list="items">
            <template v-for="(item, index) in items">
                <options-criteria-color-form v-model="items[index]" :key="item.tempId" @input="emit">
                    <template #activator="{ attrs, on }">
                        <options-criteria-color-item class="ma-1" :value="item" v-bind="{ attrs, on }" @remove="remove(item)" />
                    </template>
                </options-criteria-color-form>
            </template>
            <options-criteria-color-form @input="add">
                <template #activator="{ attrs, on }">
                    <v-chip outlined label class="ma-1" :ripple="false" v-bind="attrs" v-on="on">
                        <v-avatar size="40" class="v-sheet--outline">
                            <v-icon>mdi-plus</v-icon>
                        </v-avatar>
                        항목추가
                    </v-chip>
                </template>
            </options-criteria-color-form>
        </draggable>
    </v-chip-group>
</template>

<script>
import { initProductOptionCriterionValue } from "@/assets/variables";
import Draggable from "vuedraggable";
import OptionsCriteriaColorForm from "./options-criteria-color-form.vue";
import OptionsCriteriaColorItem from "./options-criteria-color-item.vue";

export default {
    components: {
        Draggable,
        OptionsCriteriaColorForm,
        OptionsCriteriaColorItem,
    },
    props: {
        value: { type: Array, default: initProductOptionCriterionValue },
    },
    data: () => ({
        items: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.items = [...this.value].map(initProductOptionCriterionValue);
        },
        emit(item) {
            this.$emit("input", this.items);
            this.$emit("updateCriteria", item);
        },
        add(item) {
            this.items.push(item);
            this.emit(item);
        },
        remove(item) {
            this.items = this.items.filter(({ tempId }) => tempId != item.tempId);
            this.emit(item);
        },
    },
};
</script>

<style></style>
