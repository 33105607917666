<template>
    <v-layout justify-center>
        <v-responsive max-width="1000px" width="100%" height="100%">
            <list-heading title="휴무일 지정" v-model="showsSearch" />

            <v-card tile>
                <v-row>
                    <v-col cols="4" class="d-flex justify-center">
                        <v-date-picker v-model="items" multiple :allowed-dates="(val) => $dayjs(val).day() !== 0 && $dayjs(val).day() !== 6" @input="setHolidays" />
                    </v-col>
                    <v-divider vertical />
                    <v-col>
                        <v-chip-group column>
                            <v-chip v-for="(item, index) in items" :key="index" close outlined @click:close="close(item)">
                                {{ item }}
                            </v-chip>
                        </v-chip-group>
                    </v-col>
                </v-row>
                <v-divider />
                <v-card-actions>
                    <span class="font-weight-bold text-red-lighten-1">{{ simulateText }}</span
                    >일 이전까지의 예치금이 확정됩니다.
                    <v-spacer />
                    <v-btn
                        v-bind="{ ...attrs_input__console }"
                        @click="
                            () => {
                                items = [];
                            }
                        "
                        >전체 제거</v-btn
                    >
                    <v-btn v-bind="{ ...attrs_input__console }" color="primary" @click="save">저장하기</v-btn>
                </v-card-actions>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input__console } from "@/assets/variables/attrs";

import ListHeading from "@/components/console/dumb/list-heading.vue";

export default {
    components: {
        ListHeading,
    },
    data: () => ({
        attrs_input__console,

        holidays: [],
        items: [],

        limit: 20,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,
    }),
    computed: {
        simulateText() {
            let count = 10;
            let date = this.$dayjs().add(1, "day");

            do {
                date = date.subtract(1, "day");

                if (date.day() === 0 || date.day() === 6) continue;
                // let holiday = this.holidays.some((holiday) => holiday.date === date.format("YYYY-MM-DD"));
                let holiday = this.items.some((item) => item === date.format("YYYY-MM-DD"));
                if (holiday) continue;

                count--;
            } while (0 < count);

            return date.format("YYYY-MM-DD");
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit } = this;
                var { summary, holidays } = await api.console.shop.holidays.gets({
                    headers: { skip, limit },
                });

                this.summary = summary;
                this.holidays = holidays;
                this.items = holidays.map(({ date }) => date);
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const addedHolidays = this.items.filter((item) => !this.holidays.some(({ date }) => date === item));
                const removedHolidays = this.holidays.filter(({ date }) => !this.items.includes(date));

                await Promise.all([
                    ...addedHolidays.map((holiday) => {
                        return api.console.shop.holidays.post({ date: holiday });
                    }),

                    ...removedHolidays.map((holiday) => {
                        return api.console.shop.holidays.delete(holiday);
                    }),
                ]);

                alert("저장되었습니다.");
            } finally {
                this.loading = false;
                this.search();
            }
        },

        close(item) {
            this.items.splice(this.items.indexOf(item), 1);
        },

        setHolidays() {
            this.items = this.items.sort((a, b) => new Date(a) - new Date(b));
        },
    },
};
</script>